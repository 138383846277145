import "antd/dist/reset.css";
import "../styles/fonts.scss";
import "../styles/tailwind.css";
import "../styles/globals.scss";
import "swiper/css";

import Error from "next/error";
import nextCookie from "next-cookies";
import { appWithTranslation } from "next-i18next";
import { ConfigProvider } from "antd";
import { Provider } from "react-redux";
import { store } from "../redux/store";
import { Api } from "../networks/Api";
import axios from "axios";
import { destroyCookie } from "nookies";
import redirectTo from "../components/RedirectTo";
import theme from "../theme/themeConfig";
import memoryCache from "memory-cache";

const App = ({
  Component,
  pageProps,
  categoryMenu,
  hasSale,
  hasLive,
  dataSeo,
  dataReferrer,
  profileData,
  token,
}) => {
  if (pageProps?.error) {
    return (
      <Error
        statusCode={pageProps.error.statusCode}
        title={pageProps.error.message}
      />
    );
  }

  return (
    <Provider store={store}>
      <ConfigProvider theme={theme}>
        <Component
          {...pageProps}
          categoryMenu={categoryMenu}
          hasSale={hasSale}
          hasLive={hasLive}
          dataSeo={dataSeo}
          dataReferrer={dataReferrer}
          profileData={profileData}
          token={token}
        />
      </ConfigProvider>
    </Provider>
  );
};

App.getInitialProps = async ({ Component, ctx }) => {
  const c = nextCookie(ctx);
  const { id, category } = ctx.query;
  const pathname = ctx.pathname;
  const dataReferrer = ctx.req?.headers?.referer || "";

  let pageProps = {};
  let categoryMenu, dataSeo, profileData;

  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx);
  }

  // ตรวจสอบแคชก่อนที่จะทำการเรียก API
  let cacheKey = `seoData-${id || category || pathname}`;
  let cacheData = memoryCache.get(cacheKey);

  if (!cacheData) {
    try {
      const urlSeo = id
        ? Api.getPageSEOProduct
        : category
        ? Api.getPageSEOBrand
        : Api.getPageSEOUrl;
      const postData = id
        ? { product_id: id }
        : category
        ? { brand_id: category }
        : { url: pathname };

      const [response_seo, response_article, profileRes] = await Promise.all([
        axios.post(urlSeo, postData, Api.Header()),
        category
          ? axios.post(
              `${Api.getFeedArticleByBrandWithoutHtml}`,
              { brand_name: category },
              Api.Header()
            )
          : Promise.resolve(null),
        c.token
          ? axios.get(`${Api.UserProfile}`, Api.Header(c.token))
          : Promise.resolve(null),
      ]);

      dataSeo = response_article?.data?.data || response_seo?.data?.data;

      if (profileRes?.status == 200) {
        profileData = profileRes?.data?.data;
      } else if (c.token) {
        destroyCookie(ctx, "token");
        redirectTo("/signup", { res: ctx.res, status: 301 });
      }

      // เก็บข้อมูลลงในแคช
      cacheData = { dataSeo, profileData };
      memoryCache.put(cacheKey, cacheData, 1 * 24 * 60 * 60 * 1000); // แคชข้อมูลไว้ 1 วัน
    } catch (error) {
      console.error("Error during API calls or authentication:", error);
      if (c.token) {
        destroyCookie(ctx, "token");
        redirectTo("/signup", { res: ctx.res, status: 301 });
      }
    }
  }

  const groupCategoryRes = await axios.get(
    `${Api.getGroupCategory}`,
    Api.Header()
  );
  if (groupCategoryRes?.status == 200) {
    categoryMenu = groupCategoryRes?.data?.data;
  }

  return {
    pageProps,
    categoryMenu: categoryMenu,
    hasSale: [],
    hasLive: [],
    dataSeo: cacheData?.dataSeo || dataSeo,
    dataReferrer,
    profileData: cacheData?.profileData || profileData,
    token: c.token || null,
  };
};

export default appWithTranslation(App);
