import { useRouter } from "next/router";
import NextImage from "next/image";
import ThumborJS from "thumborjs";
// import Swiper JS
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";

const imageUrl = process.env.NEXT_PUBLIC_IMAGE_URL;
const thumborKey = process.env.NEXT_PUBLIC_THUMBOR_KEY;
const thumborUrl = process.env.NEXT_PUBLIC_THUMBOR_URL;

const App = ({
  bannerData,
  indexValue,
  bannerWidth,
  bannerHeight,
  queryParam,
  firstload = false,
  isMobile,
}) => {
  const router = useRouter();

  return (
    <Swiper
      lazy={`true`}
      slidesPerView={1}
      loop={true}
      autoplay={{
        delay: 20000,
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
        dynamicBullets: false,
      }}
      modules={[Pagination, Autoplay]}
    >
      {bannerData?.map((v, index) => {
        const url = v?.event_type === "live" ? `/live` : v?.url || ``;
        const image_path = v?.[indexValue]?.replace(/\/uploads/g, "");
        const imageProduct = v?.[indexValue]
          ? `${imageUrl}${image_path}`
          : v?.[indexValue]
          ? v?.[indexValue]
          : `${process.env.NEXT_PUBLIC_FRONTEND_URL}/static/images/thumbnail.jpg`;
        const ratio = bannerWidth / bannerHeight;
        const height = Math.ceil(bannerWidth / ratio);
        const imagepath = new ThumborJS(thumborUrl, thumborKey)
          .imgpath(imageProduct)
          .resize(bannerWidth, height)
          .genurl();

        let imageProps = {};
        if (
          index == 0 &&
          firstload &&
          ((isMobile && indexValue == "mobile_image_path") ||
            (!isMobile && indexValue == "website_image_path"))
        ) {
          imageProps.priority = true;
        } else {
          imageProps.loading = "lazy";
          imageProps.placeholder = "blur";
          imageProps.blurDataURL = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=`;
        }
        return (
          <SwiperSlide
            key={`feedBannerTop-${v?.id}`}
            className="text-center"
            onClick={() => {
              if (url) {
                router.push(`${`${url}${queryParam ? `?${queryParam}` : ``}`}`);
              }
            }}
          >
            <div className="relative w-full aspect-[750/560] sm:aspect-[2592/820]">
              <NextImage
                // loader={myLoaderBanner}
                src={imagepath}
                alt="Image"
                fill
                sizes="(max-width: 1280px) 100vw"
                className={url ? "banner-img-pointer" : "banner-img"}
                formats={["image/webp", "image/jpeg", "image/png"]}
                {...imageProps}
                unoptimized
              />
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default App;
